import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#000000',
          backgroundImage: `
            radial-gradient(circle at center -20%, #030A8C 20%, transparent 40%),
            linear-gradient(180deg, rgba(3,10,140,0.2) 1%, rgba(0,0,0,1) 100%)
          `,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '100vh',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#030A8C', // Brand medium
      light: '#2D82B7', // Brand lighter
      dark: '#030140', // Brand deep
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#6463f2', // Brand accent 2
      light: '#F26463', // Brand accent 1
      contrastText: '#000000',
    },
    grey: {
      100: '#FFFFFF',
      200: '#FBFBFB',
      300: '#F1F1F1',
      400: '#CBCBCA',
      500: '#767676',
      600: '#575757',
      700: '#111111',
    },
    error: {
      100: '#FFDEDE',
      200: '#F0B3B3',
      300: '#EAB8C8',
      400: '#EB6A6A',
      500: '#DD444A',
      600: '#DB0000',
      main: '#DD444A',
    },
    warning: {
      100: '#FFF9DB',
      200: '#FFF2B2',
      300: '#FFE69A',
      400: '#FFD85A',
      500: '#FFC107',
      600: '#FFA000',
      main: '#FFC107',
    },
    success: {
      100: '#E8F5E9',
      200: '#C8E6C9',
      300: '#A5D6A7',
      400: '#81C784',
      500: '#66BB6A',
      600: '#388E3C',
      main: '#4CAF50',
    },
    info: {
      100: '#E3F2FD',
      200: '#BBDEFB',
      300: '#90CAF9',
      400: '#64B5F6',
      500: '#42A5F5',
      600: '#1976D2',
      main: '#2196F3',
    },
    background: {
      default: '#ffffff',
      paper: '#f9f9f9',
    },
    text: {
      primary: '#ffffff',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '4rem',
      lineHeight: 1.5,
    },
    h2: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.5,
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.5rem',
      lineHeight: 1.4,
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 1.4,
    },
    h5: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.3,
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.5,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.5,
    },
    button: {
      fontWeight: 500,
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.5,
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      textTransform: 'uppercase',
    },
  },
});

export default theme;
