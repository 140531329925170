import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { CustomerInfo } from '../../components/register/types';

interface AuthState {
  isAuthenticated: boolean;
  expiresAt: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  expiresAt: null,
  firstName: null,
  lastName: null,
  email: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (
      state,
      action: PayloadAction<{ expiresAt: string; customerInfo: CustomerInfo }>
    ) => {
      state.isAuthenticated = true;
      state.expiresAt = action.payload.expiresAt;
      state.firstName = action.payload.customerInfo.firstName;
      state.lastName = action.payload.customerInfo.lastName;
      state.email = action.payload.customerInfo.email;
    },
    updateUserInfo: (
      state,
      action: PayloadAction<{ firstName: string; lastName: string; email: string }>
    ) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.expiresAt = null;
      state.firstName = null;
      state.lastName = null;
      state.email = null;
    },
  },
});

export const { loginSuccess, updateUserInfo, logout } = authSlice.actions;

export default authSlice.reducer;
