import { lazy, Suspense } from 'react';

const ProfilePage = lazy(() => import('../pages/Profile'));

export const ProfileRoutes = [
  {
    path: 'profile',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ProfilePage />
      </Suspense>
    ),
  },
];
