import { alpha } from '@mui/material/styles';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import type { NavItemProps } from '../CustomNavLink';

const Home = ({ ActiveComp }: NavItemProps) => (
  <Box
    sx={(theme) => ({
      alignItems: 'center',
      bgcolor: ActiveComp ? alpha(theme.palette.text.primary, 0.15) : 'inherit',
      borderLeft: `1px solid ${theme.palette.text.primary}`,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      padding: '1rem',
    })}
  >
    <IconButton size="small" color="inherit">
      <HomeOutlined />
    </IconButton>
    {ActiveComp && <ActiveComp />}
  </Box>
);

export default Home;
