import { useSelector } from 'react-redux';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import ActiveBadge from './NavItems/ActiveBadge';
import type { RootState } from '../../../store';
import { baseRoutes } from '../../../constants/routes.constants';

export type NavItemProps = {
  ActiveComp: React.ComponentType | null;
};

type CustomNavLinkProps = {
  NavItem: React.ComponentType<NavItemProps>;
  to: string;
};

const CustomNavLink: React.FC<CustomNavLinkProps> = ({ to, NavItem }) => {
  const theme = useTheme();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  let navTo = to;

  if (to === '/profile' && !isAuthenticated) {
    navTo = baseRoutes.signIn;
  }

  const resolvedNavTo = useResolvedPath(navTo);
  const match = useMatch({ path: resolvedNavTo.pathname, end: true });

  return (
    <NavLink to={navTo} style={{ color: theme.palette.text.primary, textDecoration: 'none' }}>
      <NavItem ActiveComp={match ? ActiveBadge : null} />
    </NavLink>
  );
};

export default CustomNavLink;
