import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes/theme';

import Header from './components/Header';
import Footer from './components/Footer';
import { AppRoutes } from './routes';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import { Provider } from 'react-redux';
import { Box } from '@mui/material';

const Layout = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />

          <Header />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '90vh',
            }}
          >
            <Box
              component="main"
              sx={{
                flex: 1,
              }}
            >
              <Outlet />
            </Box>

            <Footer />
          </Box>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: AppRoutes,
  },
]);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
