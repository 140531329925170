import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import {
  ABOUT_US_TITLE,
  PRIVACY_POLICY_TITLE,
  TERM_OF_USE_TITLE,
  COPYRIGHT_TEXT,
} from '../../constants/strings.constant';
import { baseRoutes } from '../../constants/routes.constants';

export default function Footer() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: 'secondary.contrastText',
        color: '#fff',
        width: '100%',
        bottom: 0
      }}
    >
      <Box>
        <Link
          component={RouterLink}
          to={baseRoutes.aboutUs}
          color="inherit"
          underline="none"
          sx={{ marginRight: '20px' }}
          variant="body2"
        >
          {ABOUT_US_TITLE}
        </Link>
        <Link
          component={RouterLink}
          to={baseRoutes.privacyPolicy}
          color="inherit"
          underline="none"
          sx={{ marginRight: '20px' }}
          variant="body2"
        >
          {PRIVACY_POLICY_TITLE}
        </Link>
        <Link
          component={RouterLink}
          to={baseRoutes.terms}
          color="inherit"
          underline="none"
          variant="body2"
        >
          {TERM_OF_USE_TITLE}
        </Link>
      </Box>

      <Typography variant="body2" color="inherit">
        {COPYRIGHT_TEXT}
      </Typography>
    </Box>
  );
}
