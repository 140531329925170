import { alpha } from '@mui/material/styles';
import PersonOutline from '@mui/icons-material/PersonOutline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import type { NavItemProps } from '../CustomNavLink';
import theme from '../../../../themes/theme';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const Profile = ({ ActiveComp }: NavItemProps) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  return (<Box
    sx={(theme) => ({
      alignItems: 'center',
      bgcolor: ActiveComp ? alpha(theme.palette.text.primary, 0.15) : 'inherit',
      borderLeft: `1px solid ${theme.palette.text.primary}`,
      borderRight: `2px solid ${theme.palette.text.primary}`,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      padding: '1rem',
    })}
  >
    <IconButton size="small" edge="end"   sx={{
          color: theme.palette.primary.contrastText,
          backgroundColor: isAuthenticated ?  theme.palette.secondary.main : 'transparent', 
        }}>
      <PersonOutline />
    </IconButton>
    {ActiveComp && <ActiveComp />}
  </Box>)
};

export default Profile;
