import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import searchVideosReducer from './searchVideosSlice';
import latestVideosReducer from './latestVideosSlice';
import affinityReducer from './affinitySlice'

const rootReducer = combineReducers({
  auth: authReducer,
  search: searchVideosReducer,
  latest: latestVideosReducer,
  affinity: affinityReducer
});

export default rootReducer;
