import Badge from '@mui/material/Badge';

const Active = () => (
  <Badge
    variant="dot"
    sx={(theme) => ({
      height: '.5rem',
      width: '.5rem',
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[300],
      position: 'absolute',
      bottom: '-2px',
    })}
  />
);

export default Active;
