import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import CustomNavLink from './components/CustomNavLink';
import Home from './components/NavItems/Home';
import logo from '../../assets/logo-thecodeworld.png';
import Profile from './components/NavItems/Profile';
import starSvg from '../../assets/Star.svg';

const Header = () => (
  <Box sx={{ flexGrow: 1, marginLeft: '3.75rem', marginRight: '3.75rem' }}>
    <AppBar position="static" color="transparent">
      <Toolbar
        sx={(theme) => ({
          borderLeft: `2px solid ${theme.palette.text.primary}`,
          boxShadow: 'none',
          borderTop: `2px solid ${theme.palette.text.primary}`,
          borderBottom: `2px solid ${theme.palette.text.primary}`,
          display: 'flex',
        })}
      >
        <IconButton size="small" edge="start" color="inherit" sx={{ mr: 0, padding: 0 }}>
          <img src={starSvg} alt="Star Icon" style={{ width: 34, height: 34 }} />
        </IconButton>
        <Box
          component="img"
          src={logo}
          alt="The Code Word"
          sx={{
            display: { xs: 'none', sm: 'block' },
            height: 21,
            width: 216,
            ml: 1,
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' }, marginRight: '-25px' }}>
          <CustomNavLink to="/" NavItem={Home} />
          <CustomNavLink to="/profile" NavItem={Profile} />
        </Box>
      </Toolbar>
    </AppBar>
  </Box>
);

export default Header;
