import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Video } from '../types/searchTypes';
import { initialState } from '../types/latestTypes';

const latestVideosSlice = createSlice({
  name: 'latest',
  initialState,
  reducers: {
    setVideos(state, action: PayloadAction<Video[]>) {
      state.videos = action.payload;
    },
    clearVideos(state) {
      state.videos = [];
    },
  },
});

export const { setVideos, clearVideos } = latestVideosSlice.actions;
export default latestVideosSlice.reducer;