import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_AFFINITY } from '../../constants/search.constants';

interface AffinityState {
  affinity: number;
}

const initialState: AffinityState = {
  affinity: DEFAULT_AFFINITY,
};

const affinitySlice = createSlice({
  name: 'affinity',
  initialState,
  reducers: {
    setAffinity(state, action: PayloadAction<number>) {
      state.affinity = action.payload;
    },
  },
});

export const { setAffinity } = affinitySlice.actions;
export default affinitySlice.reducer;
