export enum PageName {
  ABOUT_US = 'about-us',
  ACCOUNT = 'account',
  CUSTOMER_PASSWORD = 'reset-customer-password',
  LOGOUT = 'logout',
  PRIVACY_POLICY = 'privacy-policy',
  PROFILE = 'profile',
  RECOVER_CUSTOMER = 'recover-customer',
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  TERMS = 'terms',
}

export const baseRoutes = Object.freeze({
  root: '/',
  aboutUs: `/${PageName.ABOUT_US}`,
  account: `/${PageName.ACCOUNT}`,
  customerPassword: `/${PageName.CUSTOMER_PASSWORD}`,
  logout: `/${PageName.LOGOUT}`,
  privacyPolicy: `/${PageName.PRIVACY_POLICY}`,
  profile: `/${PageName.PROFILE}`,
  recoverCustomer: `/${PageName.RECOVER_CUSTOMER}`,
  resetCustomerPassword: `/${PageName.CUSTOMER_PASSWORD}`,
  signIn: `/${PageName.SIGN_IN}`,
  signUp: `/${PageName.SIGN_UP}`,
  terms: `/${PageName.TERMS}`,
});

export const profileRoutes = Object.freeze({
  orders: `/${PageName.PROFILE}/orders`,
  information: `/${PageName.PROFILE}/information`,
  payment: `/${PageName.PROFILE}/payment`,
});

export const apiBaseRoutes = Object.freeze({
  auth: 'auth',
});

export const apiAuthRoutes = Object.freeze({
  signIn: `/${apiBaseRoutes.auth}/${PageName.SIGN_IN}`,
  signUp: `/${apiBaseRoutes.auth}/${PageName.SIGN_UP}`,
  logout: `/${apiBaseRoutes.auth}/${PageName.LOGOUT}`,
  recoverCustomer: `/${apiBaseRoutes.auth}/${PageName.RECOVER_CUSTOMER}`,
  resetCustomerPassword: `/${apiBaseRoutes.auth}/${PageName.CUSTOMER_PASSWORD}`,
});
