const IMAGE_SCENE_TITLE = 'Scene and Frame Detection';
const IMAGE_SCENE_DETAIL =
  'Show and highlight the precise moments in the video where your search term appears or is mentioned';
const TRANSCRIPTION_SCENE_TITLE = 'Audio talk about your search';
const TRANSCRIPTION_SCENE_DETAIL =
  'Show videos where the audio tracks for mentions of your search term, helping you find relevant content based on what is being said';
const DESCRIPTION_SCENE_TITLE = 'Video Description Search';
const DESCRIPTION_SCENE_DETAIL =
  'Show results which descriptions or tags match your search term, they always redirect to the start of the video';
const VIEW_MORE_RESULTS = 'view more results';
const NEW_ICON_TEXT = 'New';
const ABOUT_US_TITLE = 'About us';
const PRIVACY_POLICY_TITLE = 'Privacy Policy';
const TERM_OF_USE_TITLE = 'Terms Of Use';
const COPYRIGHT_TEXT = 'Copyright © 2024 Young Hollywood LLC';
const BACK_TEXT = 'Back';
const TITLE_HOME_P1 = 'Discover Exclusive Video';
const TITLE_HOME_P2 = 'Content: Events, Celebrities';
const TITLE_HOME_P3 = 'Sports Concerts & More';
const SUBTITLE_HOME_P1 = 'Latest integration for advanced video search just arrived.';
const SUBTITLE_HOME_P2 = `Explore a treasure trove of VIP video content, from live sports to
                        concerts, fashion shows, and more. Elevate your content discovery
                        with our AI-powered platform, where every search uncovers the most
                        exclusive and high-impact footage, tailored just for you.`;
const WAVE_TEXT = '~~~~~~~~~~';
const RESULTS = 'RESULTS';
const NO_RESULTS = 'NO RESULTS';
const NO_RESULTS_MSG = `We couldn't find any videos matching your search. Try refining
                        your keywords to discover more.`;
const TOP_PICKS = 'TOP PICKS FOR YOU';
const SEARCH_BAR_MSG = 'Search the best videos and discover VIP content...';

// Profile
const DIALOG_DESCRIPTION = `Are you sure you want to log out of Young Hollywood? You'll lose access to play videos and make purchases.`;
const LOGOUT = 'Logout';
const LOGOUT_MSG =
  'Are you sure you want to log out of Young Hollywood? You’ll lose access to play videos and make purchases.';
const MY_INFORMATION = 'My Information';
const MY_INFORMATION_DESCRIPTION = 'Here you can edit or consult your details';
const MY_INFORMATION_SUBMIT = 'Save';
const MY_ORDERS = 'My Orders';
const MY_ORDERS_DESCRIPTION = `Start exploring our catalog to uncover the best videos. Whether it's celebrity events, concerts, or exclusive footage, there's something waiting just for you.`;
const MY_ORDERS_NOTHING_TO_SEE = 'nothing to see here';
const MY_INFORMATION_INPUTS = {
  NAME: { label: '* Name', placeHolder: 'Enter your first name' },
  LAST_NAME: { label: '* Last name', placeHolder: 'Enter your last name' },
  EMAIL: { label: '* Email', placeHolder: 'Enter your email' },
  PASSWORD: {
    label: '* Password',
    placeHolder: 'Password',
    helperText: `Your password must be at least 8 and up to 100 characters, contain uppercase and lowercase letters, at least two numbers and can't begin or end with a space.`,
  },
  COMPANY: { label: '* Company', placeHolder: 'Enter your company' },
};
const MY_ORDERS_SUBMIT = 'explore now';
const PAYMENT = 'Payment';
const PROFILE_GREETING = 'Hello 👋';
const PROFILE_TITLE = 'My Profile';
const BACK_TO_RESULTS_TEXT = 'Back to results';
const ITEM_ID_TEXT = 'Item Id';
const DESCRIPTION_TEXT = 'Description';
const TAGS_TEXT = 'Tags';
const FRAMES_FROM_VIDEOS_TEXT = 'Frames from videos sorted by relevance';
const RESOLUTION_TEXT = 'Resolution';
const MORE_DETAILED_INFORMATION_TEXT =
  'More detailed information about the premium license included in the next step after the purchase';
const FRAME_FOR_VIDEOS_TEXT = 'Frames from videos sorted by relevance';
const FILTER_BY_TEXT = 'Filter by';
const NO_RESULTS_TEXT = 'No results';
const NO_FRAMES_TEXT = 'No frames match your filters. Please try using different filters';
const ALL_SELECTOR_TEXT = 'All';
const VIDEO_SELECTOR_TEXT = 'Video';
const AUDIO_SELECTOR_TEXT = 'Audio';
const TEXT_SELECTOR_TEXT = 'Text';
const USD_CURRENCY = 'USD';
const PRICE_TEXT = 'Price';
const SECONDS_TEXT = 'seconds';
const PER_SECOND_TEXT = 'per second';
const TOTAL_TEXT = 'Total';
const STARTING_TEXT = 'Starting';
const FINISHING_TEXT = 'Finishing';
const SELECT_USAGE_TYPE_TEXT = 'Select the usage type for your purchase';
const ALL_MEDIA_TYPE_TEXT = 'All Media';
const ALL_MEDIA_PLUS_AI_TYPE_TEXT = 'All Media + AI Modifications';
const TERRITORY_TEXT = 'Territory';
const PERIOD_TEXT = 'Period';
const PERIOD_DISCLAIMER =
  'Period refers to the duration for which you have the rights to own and use the video';
const DOMESTIC_TEXT = 'Domestic (Only USA)';
const DOMESTIC_AND_WORLDWIDE_TEXT = 'Domestic and Worldwide';
const PERPETUITY_TEXT = 'Perpetuity';
const ONE_YEAR_TEXT = '1 Year';
const BUY_NOW_TEXT = 'Buy Now';
const LICENSE_DISCLAIMER_TEXT = 'This license allows AI-generated modifications to the video';
const PERIOD_DISCLAIMER_TEXT =
  'Period refers to the duration for which you have the rights to own and use the video';
const SD = 'SD';
const HD = 'HD';
const FULL_HD = 'Full HD';
const FOUR_K = '4K';
const VIDEO_NOT_FOUND_TEXT = 'Video not found';
const VIDEO_NOT_AVAILABLE_TEXT =
  'This video is not available, please try with a different one or perform a new search';
const LEAVE_SEARCH_TITLE = 'Leave search?';
const LEAVE_SEARCH_DESCRIPTION = `If you leave, your search progress will be lost, including any keywords 
                                  and filters you've applied. Are you sure you want to go?`;
const LEAVE_ACCEPT = 'LEAVE';
const LEAVE_CANCEL = 'BACK TO SEARCH';
const HOME_TEXT = 'Home';
const FINISHING_TIME_AFTER_START_TIME_ERROR = 'Finishing time must be after the start time';
const TIME_EXCEED_VIDEO_LENGTH = `The time you entered exceeds the video's length`;
const INVALID_TIME_FORMAT = 'Please review the format, it needs to be hh:mm:ss';
const RESET_PASSWORD_SUCCESSFUL_TITLE = 'Password updated';
const RESET_PASSWORD_SUCCESSFUL_DESCRIPTION = 'Your password has been updated correctly.';

// Login / Sign Up
const LOGIN = 'Login';
const SIGN_UP = 'Sign up';
const SIGN_UP_FORM_TITLE = 'CREATE YOUR ACCOUNT';
const SIGN_UP_FORM_DESCRIPTION = 'Enter your name, email, and create a strong password.';
const SIGN_IN_FORM_TITLE = 'WELCOME BACK';
const SIGN_IN_FORM_DESCRIPTION =
  'Enter your email and password to access your account and discover more videos.';
const SIGN_IN_SUCCESSFUL_TITLE = 'You have successfully logged in.';
const SIGN_UP_SUCCESSFUL_TITLE = 'Great! Registration success';
const SIGN_UP_SUCCESSFUL_DESCRIPTION =
  'Your account is now active. Start discovering premium videos, customize your searches, and take advantage of everything our platform has to offer.';

// Register
const REGISTER_DIALOG_TITLE = 'Leave page?';
const REGISTER_DIALOG_DESCRIPTION =
  'If you leave this page without creating your account, your changes will be lost. Are you sure you want to proceed?';
const REGISTER_DIALOG_BUTTON_TEXT_1 = 'Leave Page';
const REGISTER_DIALOG_BUTTON_TEXT_2 = 'Stay';

//Form
const FORM_ERROR_MESSAGE_TITLE = 'Incorrect email or password';
const FORM_ERROR_MESSAGE_DESCRIPTION =
  'We did not recognize either your email or password. Please try again.';

// Header
const HEADER_ALERT = 'There was a problem logging out. Please try again.';

// Input
const INPUT_ERROR_MESSAGE_1 = 'Email has already been taken';
const INPUT_ERROR_MESSAGE_2 =
  'This email address is already in use. Please try a different one or log in.';

// Password
const RESET_PASSWORD_FORM_TITLE = 'RESET ACCOUNT PASSWORD';
const RESET_PASSWORD_FORM_DESCRIPTION =
  'Your new password must be different from previous used passwords.';
const RECOVER_PASSWORD_REQUEST_SEND_TITLE = 'Request Sent';
const RECOVER_PASSWORD_REQUEST_TITLE = 'Forgot Password';
const RECOVER_PASSWORD_DESCRIPTION =
  'Enter the email address associated with your account to receive a link to reset your password.';

// Other
const BACK = 'Back';
const CONTINUE = 'Continue';

const UNLOCK_VIDEO_CONTENT = 'Unlock Video Content';
const UNLOCK_VIDEO_CONTENT_MSG =
  'It looks like you’re not signed in. Please log in to watch the videos.';

export {
  IMAGE_SCENE_TITLE,
  IMAGE_SCENE_DETAIL,
  TRANSCRIPTION_SCENE_TITLE,
  TRANSCRIPTION_SCENE_DETAIL,
  DESCRIPTION_SCENE_TITLE,
  DESCRIPTION_SCENE_DETAIL,
  VIEW_MORE_RESULTS,
  NEW_ICON_TEXT,
  ABOUT_US_TITLE,
  PRIVACY_POLICY_TITLE,
  TERM_OF_USE_TITLE,
  COPYRIGHT_TEXT,
  BACK_TEXT,
  TITLE_HOME_P1,
  TITLE_HOME_P2,
  TITLE_HOME_P3,
  SUBTITLE_HOME_P1,
  SUBTITLE_HOME_P2,
  WAVE_TEXT,
  RESULTS,
  NO_RESULTS,
  NO_RESULTS_MSG,
  TOP_PICKS,
  SEARCH_BAR_MSG,
  BACK,
  LOGOUT,
  LOGOUT_MSG,
  MY_INFORMATION_DESCRIPTION,
  DIALOG_DESCRIPTION,
  MY_INFORMATION_INPUTS,
  MY_INFORMATION_SUBMIT,
  MY_INFORMATION,
  MY_ORDERS_DESCRIPTION,
  MY_ORDERS_NOTHING_TO_SEE,
  MY_ORDERS_SUBMIT,
  MY_ORDERS,
  PAYMENT,
  PROFILE_GREETING,
  PROFILE_TITLE,
  BACK_TO_RESULTS_TEXT,
  ITEM_ID_TEXT,
  DESCRIPTION_TEXT,
  TAGS_TEXT,
  FRAMES_FROM_VIDEOS_TEXT,
  RESOLUTION_TEXT,
  MORE_DETAILED_INFORMATION_TEXT,
  FRAME_FOR_VIDEOS_TEXT,
  FILTER_BY_TEXT,
  NO_RESULTS_TEXT,
  NO_FRAMES_TEXT,
  ALL_SELECTOR_TEXT,
  VIDEO_SELECTOR_TEXT,
  AUDIO_SELECTOR_TEXT,
  TEXT_SELECTOR_TEXT,
  USD_CURRENCY,
  PRICE_TEXT,
  PER_SECOND_TEXT,
  TOTAL_TEXT,
  SECONDS_TEXT,
  STARTING_TEXT,
  FINISHING_TEXT,
  SELECT_USAGE_TYPE_TEXT,
  ALL_MEDIA_TYPE_TEXT,
  ALL_MEDIA_PLUS_AI_TYPE_TEXT,
  TERRITORY_TEXT,
  PERIOD_TEXT,
  PERIOD_DISCLAIMER,
  DOMESTIC_TEXT,
  DOMESTIC_AND_WORLDWIDE_TEXT,
  PERPETUITY_TEXT,
  ONE_YEAR_TEXT,
  BUY_NOW_TEXT,
  LICENSE_DISCLAIMER_TEXT,
  PERIOD_DISCLAIMER_TEXT,
  SD,
  HD,
  FULL_HD,
  FOUR_K,
  VIDEO_NOT_FOUND_TEXT,
  VIDEO_NOT_AVAILABLE_TEXT,
  LEAVE_SEARCH_TITLE,
  LEAVE_SEARCH_DESCRIPTION,
  LEAVE_ACCEPT,
  LEAVE_CANCEL,
  HOME_TEXT,
  FINISHING_TIME_AFTER_START_TIME_ERROR,
  TIME_EXCEED_VIDEO_LENGTH,
  INVALID_TIME_FORMAT,
  LOGIN,
  SIGN_UP,
  REGISTER_DIALOG_TITLE,
  REGISTER_DIALOG_DESCRIPTION,
  REGISTER_DIALOG_BUTTON_TEXT_1,
  REGISTER_DIALOG_BUTTON_TEXT_2,
  FORM_ERROR_MESSAGE_TITLE,
  FORM_ERROR_MESSAGE_DESCRIPTION,
  HEADER_ALERT,
  INPUT_ERROR_MESSAGE_1,
  INPUT_ERROR_MESSAGE_2,
  SIGN_UP_FORM_TITLE,
  SIGN_UP_FORM_DESCRIPTION,
  SIGN_IN_FORM_TITLE,
  SIGN_IN_FORM_DESCRIPTION,
  SIGN_IN_SUCCESSFUL_TITLE,
  SIGN_UP_SUCCESSFUL_TITLE,
  SIGN_UP_SUCCESSFUL_DESCRIPTION,
  RESET_PASSWORD_SUCCESSFUL_TITLE,
  RESET_PASSWORD_SUCCESSFUL_DESCRIPTION,
  CONTINUE,
  RESET_PASSWORD_FORM_TITLE,
  RESET_PASSWORD_FORM_DESCRIPTION,
  RECOVER_PASSWORD_REQUEST_SEND_TITLE,
  RECOVER_PASSWORD_REQUEST_TITLE,
  RECOVER_PASSWORD_DESCRIPTION,
  UNLOCK_VIDEO_CONTENT,
  UNLOCK_VIDEO_CONTENT_MSG,
};
