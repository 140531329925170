import { lazy, Suspense } from 'react';

const Cart = lazy(() => import('../pages/Cart'));

export const CartRoutes = [
  {
    path: 'cart',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Cart />
      </Suspense>
    ),
  },
];
